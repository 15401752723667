import { ArrayElement, ISO8601Date } from '.'
import { CombinedAvatarItemType } from '../../avatar/avatarItems'
import { _AvatarColor } from '../../avatar/avatarItems'

export const LegacyAvatarSkinColor = ['skin1', 'skin2', 'skin3', 'skin4', 'skin5', 'skin6', 'skin7', 'skin8', 'skin9'] as const
export type LegacyAvatarSkinColor = ArrayElement<typeof LegacyAvatarSkinColor>

export const LegacyAvatarEyeType = ['normal', 'wide', 'wideOpen', 'wideTired', 'closed', 'squint'] as const
export type LegacyAvatarEyeType = ArrayElement<typeof LegacyAvatarEyeType>

export const LegacyAvatarEyeColor = ['eye1', 'eye2', 'eye3', 'eye4', 'eye5'] as const
export type LegacyAvatarEyeColor = ArrayElement<typeof LegacyAvatarEyeColor>

export const LegacyAvatarMouthType = ['happy', 'unhappy', 'worried', 'sad', 'smirk', 'grin', 'annoyed', 'tongue', 'smiling', 'lips', 'eek', 'oh'] as const
export type LegacyAvatarMouthType = ArrayElement<typeof LegacyAvatarMouthType>

export const LegacyAvatarHairColor = ['hair1', 'hair2', 'hair3', 'hair4', 'hair5', 'hair6', 'hair7', 'hair8', 'hair9', 'hair10', 'hair11', 'hair12', 'hair13', 'hair14', 'hair15', 'hair17', 'hair18', 'hair19', 'hair20', 'hair21'] as const
export type LegacyAvatarHairColor = ArrayElement<typeof LegacyAvatarHairColor>

export const LegacyAvatarHairType = ['boy1', 'boy2', 'boy3', 'boy4', 'boy5', 'boy6', 'boy7', 'boy8', 'boy9', 'boy10', 'boy11', 'boy12', 'boy13', 'boy14', 'boy15', 'boy16', 'boy17', 'girl1', 'girl2', 'girl3', 'girl4', 'girl5', 'girl6', 'girl7', 'girl8', 'girl9', 'girl10', 'girl11', 'girl12', 'girl13', 'girl14', 'girl15', 'girl16'] as const
export type LegacyAvatarHairType = ArrayElement<typeof LegacyAvatarHairType>

export const LegacyAvatarColor = ['col1', 'col2', 'col3', 'col4', 'col5', 'col6', 'col7', 'col8', 'col9', 'col10', 'col11', 'col12', 'col13', 'col14', 'col15', 'col16', 'col17', 'col18', 'col19', 'col20'] as const
export type LegacyAvatarColor = ArrayElement<typeof LegacyAvatarColor>

export const LegacyAvatarShirtType = ['plain', 'stripe', 'stripe2', 'hex', 'pocket', 'circle', 'vee', 'wide', 'zip', 'buttons', 'buttons2', 'collar', 'veeCollar', 'collarButtons', 'zigZag', 'wave', 'pullStrings', 'tassles', 'shirtflower', 'shirtpearls', 'shirtmidstripe', 'shirtvfrills', 'shirthzigzag', 'shirthijab', 'shirthalloween', 'shirtbowtie', 'shirttie', 'shirtcheck', 'shirtfootball1', 'shirtfootball2', 'shirtfootball3', 'shirtfootball4', 'shirtfootball5', 'shirtfootball6', 'shirtfootball7', 'shirtfootball8', 'shirtfootball9', 'shirtfootball10', 'shirtfootball11', 'shirtfootball12', 'shirtfootball13', 'shirtfootball14', 'shirtfootball15', 'shirtfootball16', 'shirtfootball17', 'shirtfootball18', 'shirtfootball19', 'shirtfootball20', 'shirtfootball21', 'shirtfootball22', 'shirtfootball23', 'shirtfootball24'] as const
export type LegacyAvatarShirtType = ArrayElement<typeof LegacyAvatarShirtType>

export const LegacyAvatarBackgroundType = ['plain', 'pattern1', 'pattern2', 'pattern3', 'pattern4', 'pattern5', 'pattern6', 'pattern7', 'pattern8', 'pattern9', 'pattern10', 'sea', 'desert', 'beach', 'space', 'forest', 'mountains', 'egypt', 'castle', 'hearts', 'rainbow', 'snowy', 'sea2', 'zodiac', 'art', 'world', 'world2', 'map', 'music', 'romans', 'chemistry', 'football', 'balloons'] as const
export type LegacyAvatarBackgroundType = ArrayElement<typeof LegacyAvatarBackgroundType>

export const LegacyAvatarAccessory = ['flowerHairband', 'starHeadband', 'greenHeadband', 'orangeHeadband', 'redflower', 'greenhairbow', 'smallgreenbows', 'hairpearls', 'hatkippah', 'pinkHeadphones', 'greenHeadphones', 'pinkCap', 'blueCap', 'blueTassleHat', 'pinkWoolyHat', 'blueWoolyHat', 'greenWoolyHat', 'pinkScarf', 'blueScarf', 'laurel', 'hatberetred', 'hatcapbackwards', 'headScarf', 'rabbitearsheadband', 'jesterHat', 'pirateHat', 'cowboyhat', 'astronaut', 'sombrero', 'wizardhat', 'witchhat', 'hatchef', 'crown', 'helmetviking', 'helmetegypt', 'hatsanta', 'headbandbee'] as const
export type LegacyAvatarAccessory = ArrayElement<typeof LegacyAvatarAccessory>

export const LegacyAvatarFace = ['squareglasses', 'roundglasseswhite', 'roundGlasses', 'angledGlasses', 'coolShades', 'darkShades', 'facemousenose', 'goldhoopearrings', 'beardsmall', 'longmustache', 'glasseslines', 'facelightning', 'disguiseMask', 'batmanMask', 'scaryMask', 'eyePatch', 'catMask', 'puppyMask', 'pandaMask', 'pigMask', 'giraffeMask', 'tigerMask', 'zebraMask', 'dogMask', 'monkeyMask', 'elephantMask', 'lionMask', 'wand', 'snorkel', 'reindeerMask', 'unicornMask', 'wingsbee'] as const
export type LegacyAvatarFace = ArrayElement<typeof LegacyAvatarFace>

export const AvatarModifierType = ['reward', 'fine'] as const
export type AvatarModifierType = ArrayElement<typeof AvatarModifierType>

export const AllLegacyAvatarItemKeys = [...LegacyAvatarEyeType, ...LegacyAvatarMouthType, ...LegacyAvatarHairType, ...LegacyAvatarShirtType, ...LegacyAvatarBackgroundType, ...LegacyAvatarAccessory, ...LegacyAvatarFace]

export interface Avatar { // to be changed for LegacyAvatar after new User Avatar is deployed
  accessory?: LegacyAvatarAccessory
  backgroundType?: LegacyAvatarBackgroundType
  backgroundColor?: LegacyAvatarColor
  shirtType?: LegacyAvatarShirtType
  eyeType?: LegacyAvatarEyeType
  mouthType?: LegacyAvatarMouthType
  hairType?: LegacyAvatarHairType
  hairColor?: LegacyAvatarHairColor
  eyeColor?: LegacyAvatarEyeColor
  skinColor?: LegacyAvatarSkinColor
  shirtColor?: LegacyAvatarColor
  shirtColor2?: LegacyAvatarColor
  face?: LegacyAvatarFace
}

export interface AvatarPurchaseInfo {
  id: number
  user_id: number
  type: CombinedAvatarItemType | AvatarModifierType
  key: string | null
  timestamp: ISO8601Date
  cost: number
}

export const legacyAvatarItems = {
  accessory: LegacyAvatarAccessory,
  backgroundType: LegacyAvatarBackgroundType,
  backgroundColor: LegacyAvatarColor,
  shirtType: LegacyAvatarShirtType,
  eyeType: LegacyAvatarEyeType,
  mouthType: LegacyAvatarMouthType,
  hairType: LegacyAvatarHairType,
  hairColor: LegacyAvatarHairColor,
  eyeColor: LegacyAvatarEyeColor,
  skinColor: LegacyAvatarSkinColor,
  shirtColor: LegacyAvatarColor,
  shirtColor2: LegacyAvatarColor,
  face: LegacyAvatarFace
}