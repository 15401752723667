



/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
// @ts-ignore
import { Api, UserModel } from '../api'
import { UserAvatar } from './avatarItems'
import { avatarToHash } from './avatarUtils'

export interface UserModelForAvatar {
  id?: UserModel['id']
  avatar_hash?: UserModel['avatar_hash']
  avatar?: UserModel['avatar']
}

/**
 * Avatar Component
 * 
 * Use this to display a user's avatar
 * 
 * Inputs:
 * 
 * @props user:
 *    This accepts:
 *    * a full UserModel object
 *      OR
 *    * a partial UserModelForAvatar object with at least one of the following properties in order of preference
 *      1) avatar_hash
 *      2) avatar (JSON String)
 *      3) id (user id)
 * 
 *  @props env:
 *      This determines which S3 bucket is pointed at when requesting the user avatar image
 */

@Component({
  components: {

  }
})
export default class Avatar extends Mixins() {
  @Prop({ required: true }) user!: UserModelForAvatar
  @Prop({ default: 'production' }) env!: 'production' | 'staging' | 'development'
  @Prop({default: 1}) scale!: number

  private src: string = ''
  private hash: string = ''
  private loading = true
  private apiTimeout: ReturnType<typeof setTimeout> | null = null

  @Watch('user') onDataUpdate () {
    if (this.user) {
      this.requestAvatar()
    }
  }

  mounted () {
    if (this.user) {
      this.requestAvatar()
    }
  }

  get imgSrc () {
    return this.src || 'https://files.edshed.com/img/avatar/avatar_loading.png'
  }

  private requestAvatar () {
    if (this.apiTimeout !== null) {
      clearTimeout(this.apiTimeout)
    }
    if (this.user.avatar_hash) {
      this.hash = this.user.avatar_hash
    } else if (this.user.avatar) {
      const data = JSON.parse(this.user.avatar) as UserAvatar
      if (!Avatar.ShaFunc || !data) {
        this.askApiToGenerate()
        return
      }
      this.hash = avatarToHash(data, (str: string) => this.getAvatarHash(str))
    } else if (this.user.id) {
      this.askApiToGenerate()
      return
    } else {
      console.error('Avatar component has been supplied with no useful info')
      this.fallbackToDefault()
      return
    }
    const image = new Image()
    image.onload = () => {
      // cached avatar found
      this.src = image.src
      this.loading = false
    }
    image.onerror = () => {
      // No avatar found - ask API to generate it
      this.askApiToGenerate()
    }
    image.src = `https://files.edshed.com/${this.env}/avatars/${this.hash}.png`
  }

  private askApiToGenerate () {
    setTimeout(async() => {
      try {
        if (!this.user.id) {
          this.fallbackToDefault()
          return
        }
        const res = await Api.generateAvatarImage(this.user.id)
        this.src = res.base64
        this.loading = false
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error(error.message)
          this.fallbackToDefault()
        }
      }
    }, 500)
  }

  private fallbackToDefault () {
    this.src = 'https://files.edshed.com/img/avatar/defaultAvatar.png'
    this.loading = false
  }

  /**
   * Function relies on a package in the project 
   * 
   * To add the sha.js function to a vue project, add the following to a project's global.ts or main.ts where Vue is initialised
   * 
   * ```
   * 
   * import Avatar from './edshed-common/avatar/Avatar.vue'
   * import shajs from 'sha.js'
   * 
   * ....
   * 
   * Vue.component('Avatar', Avatar);
   * 
   * (Avatar as any).ShaFunc = (str: string) => {
   *   return shajs('sha256').update(str).digest('hex')
   * }
   * ```
   */
  public static ShaFunc: (val: string) => string

  public getAvatarHash (val: string): string {
    if (Avatar.ShaFunc) {
      return Avatar.ShaFunc(val)
    }
    throw new Error('ShaFunc is not defined. Please ensure that ShaFunc is defined in global.ts.')
  }
}

