import { Buildable } from 'ts-essentials'
import { Avatar, LegacyAvatarAccessory, LegacyAvatarFace } from '../api/types/avatar'
import { _AvatarColor, AvatarItemInfo, _AvatarItemSlot, UserAvatar } from './avatarItems'

export const avatarHashStructure: string[] = [
  ..._AvatarColor.filter(x => x !== ''),
  ..._AvatarItemSlot.filter(x => x !== 'default')
]

export function avatarToHash (avatar: UserAvatar, hashFunc: (str: string) => string) {
  let fullAvatarConfig = ''

  avatarHashStructure.forEach((x, i) => {
    let value = ''
    const entry = (avatar as any)[x]
    if (entry) {
      if (typeof entry === 'string') {
        value = entry
      } else if ((entry as AvatarItemInfo).key) {
        value = entry.key
      }
    }
    fullAvatarConfig += `${i ? '|' : ''}${value}`
  })

  return hashFunc(fullAvatarConfig)
}

export function convertToLegacyAvatar (avatar: UserAvatar): Avatar {
  const convertedAvatar: Buildable<Avatar> = {}
  const avatarKeys = ['accessory', 'backgroundType', 'backgroundColor', 'shirtType', 'eyeType', 'mouthType', 'hairType', 'hairColor', 'eyeColor', 'skinColor', 'shirtColor', 'shirtColor2', 'face']
  for (const [key, value] of Object.entries(avatar)) {
    if (key === 'extra') { continue }
    let newkey = key
    const valueKey = typeof value === 'string' ? value : value ? (value.key ? value.key : undefined) : undefined
    // Converting new to old
    console.log(key, valueKey)
    switch (key) {
      case 'background':
        newkey = 'backgroundType'
        break
      case 'shirt':
        newkey = 'shirtType'
        break
      case 'mouth':
        newkey = 'mouthType'
        break
      case 'hair':
        newkey = 'hairType'
        break
      case 'eyes':
        newkey = 'eyeType'
        break
      case 'shirtColor1':
        newkey = 'shirtColor'
        break
    }
    if (avatarKeys.includes(newkey)) {
      switch (newkey) {
        case 'backgroundType':
          if (avatar.background && avatar.background.key === 'plain_background') {
            convertedAvatar[newkey] = 'plain'
          } else {
            convertedAvatar[newkey] = valueKey
          }
          break
        case 'shirtType':
          if (avatar.shirt && avatar.shirt.key === 'plain_shirt') {
            convertedAvatar[newkey] = 'plain'
          } else if (avatar.shirt && avatar.shirt.key === 'wide_shirt') {
            convertedAvatar[newkey] = 'wide'
          } else {
            convertedAvatar[newkey] = valueKey
          }
          break
        case 'eyeType':
          if (avatar.eyes && avatar.eyes.key === 'wide_eyes') {
            convertedAvatar[newkey] = 'wide'
          } else {
            convertedAvatar[newkey] = valueKey
          }
          break
        default:
          (convertedAvatar as any)[newkey] = valueKey
      }
    } else {
      // @ts-ignore
      if (LegacyAvatarAccessory.includes(valueKey)) {
        convertedAvatar.accessory = value.key as LegacyAvatarAccessory
      }
      // @ts-ignore
      if (LegacyAvatarFace.includes(valueKey)) {
        convertedAvatar.face = value.key as LegacyAvatarFace
      }
    }
  }
  return convertedAvatar
}
