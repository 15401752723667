

































import { avatarImageDimensions, AvatarItemInfo, AvatarItemLayer, AvatarItemSlot, AvatarLayers, _AvatarColor, _AvatarItemSlot } from "@/edshed-common/avatar/avatarItems";
import { AvatarRenderer } from "@/edshed-common/avatar/avatarRenderer";
import ModalMixin from "@/edshed-common/mixins/ModalMixin";
import ComponentHelper from "@/mixins/ComponentHelper";
import { Mixins, Component, Prop } from "vue-property-decorator";
import { ImageUpload, StoredImage } from "@/edshed-common/api";
import { LegacyAvatarColor } from "@/edshed-common/api/types/avatar";

interface Record {
  image: StoredImage
  new_image?: ImageUpload
  colors: LegacyAvatarColor[]
}

@Component({components: {}})
export default class AvatarImageUpload extends Mixins(ModalMixin, ComponentHelper) {
  @Prop({required: true}) record!: Record
  @Prop({default: `PNG @ ${avatarImageDimensions.height}px HEIGHT & ${avatarImageDimensions.width}px WIDTH x number of colourable frames`}) message!: string

  get colorSlots () {
    return _AvatarColor
  }

  private onImageReady () {
    this.$emit('image-ready')
  }

  private onColorCheckbox () {
    this.$emit('toggle-color')
  }

  private onItemColorChange () {
    this.$emit('color-change')
  }

}
