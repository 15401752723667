import { LegacyAvatarAccessory, LegacyAvatarBackgroundType, LegacyAvatarEyeColor, LegacyAvatarEyeType, LegacyAvatarFace, LegacyAvatarHairColor, LegacyAvatarHairType, LegacyAvatarMouthType, LegacyAvatarShirtType, LegacyAvatarSkinColor, legacyAvatarItems, LegacyAvatarColor } from '../api/types/avatar'
import { ImageUpload, StoredImage } from '../api'
import { PurchasableItemType } from './purchasableItems'

export enum AvatarLayers {
  canvas = 0,
  background = 5,
  chairs = 10,
  hairback = 15,
  wings = 20,
  shirt = 25,
  body = 30,
  head = 35,
  face = 40,
  onface = 45,
  facialhair = 50,
  hairfront = 55,
  masks = 60,
  hat = 65,
  held = 70,
  pet = 75,
  effects = 80
}

export const avatarImageDimensions = { width: 160 * 2, height: 184 * 2 }

// export interface AvatarItemInfo {
//   id: number
//   name: string
//   key: string
//   type: AvatarItemSlot
//   layer: number // AvatarLayers
//   colors: AvatarColor[] // Each color will look for frames in a sprite sheet. It will slice the image into parts and color them depending on the order of the array
//   cost: number // cost in honeypots
//   image?: StoredImage
//   new_image?: ImageUpload
//   hidden: boolean
// }
export interface AvatarItemInfo {
  id: number
  key: string
  name: string
  type: AvatarItemSlot
  cost: number // cost in honeypots
  layers: AvatarItemLayers // images used in avatar drawing
  image?: StoredImage | null // image used for the item thumbnail
  new_image?: ImageUpload | null // image used for the item thumbnail
  colors: AvatarColor[] // colors used for item thumbnail
  hidden: boolean
  image_version: number // Added to item key when making avatar hash - increment this number whenever an image or layer is updated
  sort_order: number
}

export interface AvatarItemCreateRequest {
  key?: string
  name: string
  type: AvatarItemSlot
  cost: number // cost in honeypots
  layers: AvatarItemLayers // images used in avatar drawin
  new_image?: ImageUpload | null // image used for the item thumbnail
  colors: AvatarColor[] // colors used for item thumbnail
  hidden: boolean
}

export interface AvatarItemEditRequest {
  name?: string
  type?: AvatarItemSlot
  cost?: number // cost in honeypots
  layers?: AvatarItemLayers // images used in avatar drawing
  new_image?: ImageUpload | null // image used for the item thumbnail
  colors?: AvatarColor[] // colors used for item thumbnail
  hidden?: boolean
}

export interface AvatarItemLayers {
  max_id: number // The maximum ID this item has had for layers. e.g. if layer id 4 is removed, there should never be another layer id 4, so the next created layer will be 5
  layers: AvatarItemLayer[]
}

export interface AvatarItemLayer {
  id: number // auto increment when creating new layers
  image?: StoredImage | null
  new_image?: ImageUpload | null
  colors: AvatarColor[] // Each color will look for frames in a sprite sheet. It will slice the image into parts and color them depending on the order of the array
  layer: number // AvatarLayers
}
export const _AvatarColor = ['', 'backgroundColor', 'hairColor', 'eyeColor', 'skinColor', 'shirtColor1', 'shirtColor2', 'headwearColor'] as const
export type AvatarColor = typeof _AvatarColor[number]

/**
 * Notes:
 *
 * "default" is currently just the head and nose - but isn't restricted to one item, so will contain all default items (not included in user avatar data and should always be hidden)
 * "extra" is unrestricted and will be used for items that shouldn't be seen as fashion accessories. e.g. wheelchairs, hearing aids, support ribbons or what have you.
*/
// export const AvatarItemSlot = ['hairType', 'face', 'accessory', 'hobby', 'pet', 'shirtType', 'backgroundType', 'eyeType', 'mouthType', 'default']
export const _AvatarItemSlot = ['default', 'background', 'facepaint', 'eyes', 'mouth', 'face', 'hair', 'shirt', 'facialhair', 'wings', 'hat', 'scarf', 'jewellery', 'hand', 'pet', 'extra'] as const
export type AvatarItemSlot = typeof _AvatarItemSlot[number]

export const CombinedAvatarItemType = [...PurchasableItemType, ..._AvatarItemSlot] as const
export type CombinedAvatarItemType = typeof CombinedAvatarItemType[number]

export interface UserAvatar {
  // color settings
  backgroundColor?: LegacyAvatarColor
  hairColor?: LegacyAvatarHairColor
  eyeColor?: LegacyAvatarEyeColor
  skinColor?: LegacyAvatarSkinColor
  shirtColor1?: LegacyAvatarColor
  shirtColor2?: LegacyAvatarColor
  headwearColor?: LegacyAvatarColor

  // items
  background?: AvatarItemInfo
  hair?: AvatarItemInfo
  eyes?: AvatarItemInfo
  mouth?: AvatarItemInfo
  facepaint?: AvatarItemInfo
  facialhair?: AvatarItemInfo
  face?: AvatarItemInfo
  jewellery?: AvatarItemInfo
  hat?: AvatarItemInfo
  shirt?: AvatarItemInfo
  scarf?: AvatarItemInfo
  wings?: AvatarItemInfo
  hand?: AvatarItemInfo
  pet?: AvatarItemInfo
  extra?: Partial<{ [key in AvatarItemInfo['key']]: AvatarItemInfo }>
}

export interface UserAvatarOptions {
  accessory?: LegacyAvatarAccessory // legacy field that is not represented in the new version
  // color settings
  backgroundColor?: LegacyAvatarColor
  hairColor?: LegacyAvatarHairColor
  eyeColor?: LegacyAvatarEyeColor
  skinColor?: LegacyAvatarSkinColor
  shirtColor1?: LegacyAvatarColor
  shirtColor2?: LegacyAvatarColor
  headwearColor?: LegacyAvatarColor

  // items
  background?: string | LegacyAvatarBackgroundType
  hair?: string | LegacyAvatarHairType
  eyes?: string | LegacyAvatarEyeType
  mouth?: string | LegacyAvatarMouthType
  facepaint?: string
  facialhair?: string
  face?: string | LegacyAvatarFace
  jewellery?: string
  hat?: string
  shirt?: string | LegacyAvatarShirtType
  scarf?: string
  wings?: string
  hand?: string
  pet?: string
  extra?: Partial<{ string: string | true | null }>
}

export const avatarColors: AvatarColors = {
  skinColors: [
    { key: 'skin1', color: '#f2e5c9' },
    { key: 'skin2', color: '#e6d1b5' },
    { key: 'skin3', color: '#d3c1b3' },
    { key: 'skin4', color: '#c7a88c' },
    { key: 'skin5', color: '#b9926f' },
    { key: 'skin6', color: '#8b6f59' },
    { key: 'skin7', color: '#926440' },
    { key: 'skin8', color: '#6e4b30' },
    { key: 'skin9', color: '#4b3932' }
  ],
  eyeColors: [
    { key: 'eye1', color: '#575756' },
    { key: 'eye2', color: '#007800' },
    { key: 'eye3', color: '#71291c' },
    { key: 'eye4', color: '#c6c6c6' },
    { key: 'eye5', color: '#00a7f3' }
  ],
  hairColors: [
    { key: 'hair1', color: '#f8f875' },
    { key: 'hair2', color: '#f8da25' },
    { key: 'hair3', color: '#f8bc28' },
    { key: 'hair4', color: '#ff9303' },
    { key: 'hair5', color: '#ec570e' },
    { key: 'hair6', color: '#71291c' },
    { key: 'hair7', color: '#715e54' },
    { key: 'hair8', color: '#8b6239' },
    { key: 'hair9', color: '#5f3813' },
    { key: 'hair10', color: '#191a1a' },
    { key: 'hair11', color: '#a49ff4' },
    { key: 'hair12', color: '#f1c7a5' },
    { key: 'hair13', color: '#f279a7' },
    { key: 'hair14', color: '#69cdb1' },
    { key: 'hair15', color: '#f24426' },
    { key: 'hair17', color: '#00A7F3' },
    { key: 'hair18', color: '#6dd130' },
    { key: 'hair19', color: '#f2e5eb' },
    { key: 'hair20', color: '#715e54' },
    { key: 'hair21', color: '#575756' }
  ],
  customColors: [
    { key: 'col1', color: '#1a54fa' },
    { key: 'col2', color: '#69cdb1' },
    { key: 'col3', color: '#f2ab30' },
    { key: 'col4', color: '#00a7f3' },
    { key: 'col5', color: '#f279a7' },
    { key: 'col6', color: '#007800' },
    { key: 'col7', color: '#6dd130' },
    { key: 'col8', color: '#f8da25' },
    { key: 'col9', color: '#f24426' },
    { key: 'col10', color: '#575756' },
    { key: 'col11', color: '#eb4863' },
    { key: 'col12', color: '#c6c6c6' },
    { key: 'col13', color: '#7f591d' },
    { key: 'col14', color: '#ffffff' },
    { key: 'col15', color: '#f6e652' },
    { key: 'col16', color: '#a130d7' },
    { key: 'col17', color: '#a49ff4' },
    { key: 'col18', color: '#9d9d9c' },
    { key: 'col19', color: '#191a1a' },
    { key: 'col20', color: '#ffd8f4' }
  ]
}

const defaultColorKeys = {
  skinColor: 'skin1',
  eyeColor: 'eye1',
  hairColor: 'hair1',
  shirtColor1: 'col1',
  shirtColor2: 'col2',
  backgroundColor: 'col12',
  headwearColor: 'col11'
}

export const defaultAvatarColors = {
  skinColor: avatarColors.skinColors.find(x => x.key === defaultColorKeys.skinColor) || avatarColors.skinColors[0],
  eyeColor: avatarColors.eyeColors.find(x => x.key === defaultColorKeys.eyeColor) || avatarColors.eyeColors[0],
  hairColor: avatarColors.hairColors.find(x => x.key === defaultColorKeys.hairColor) || avatarColors.hairColors[0],
  shirtColor1: avatarColors.customColors.find(x => x.key === defaultColorKeys.shirtColor1) || avatarColors.customColors[0],
  shirtColor2: avatarColors.customColors.find(x => x.key === defaultColorKeys.shirtColor2) || avatarColors.customColors[1],
  backgroundColor: avatarColors.customColors.find(x => x.key === defaultColorKeys.backgroundColor) || avatarColors.customColors[2],
  headwearColor: avatarColors.customColors.find(x => x.key === defaultColorKeys.headwearColor) || avatarColors.customColors[3]
}

// When the shop is loaded, undefined avatar item choices will default to these
export const defaultAvatarItems = {
  hair: 'boy1',
  shirt: 'plain',
  eyes: 'wide',
  mouth: 'smile',
  background: 'plain_background'
}

export interface AvatarItemForShop {
  item: AvatarItemInfo
  owned: boolean
  equipped: boolean
}

export function getAvatarColorHex (key: string) {
  const allColors = [...avatarColors.skinColors, ...avatarColors.hairColors, ...avatarColors.eyeColors, ...avatarColors.customColors]
  const color = allColors.find(x => x.key === key)
  if (color) {
    return color.color
  }
  return '#ffffff'
}

export interface AvatarColorSwatch {
  key: string
  color: string
}

export interface AvatarColors {
  skinColors: AvatarColorSwatch[] // skin1, skin2, skin3
  eyeColors: AvatarColorSwatch[] // eye1, eye2, eye3
  hairColors: AvatarColorSwatch[] // hair1, hair2, hair3
  customColors: AvatarColorSwatch[] // col1, col2, col3 - used for background, clothes and headcovers
}

function fakeImageObject (src: string): StoredImage {
  const url = `https://files.edshed.com/img/avatar/test/${src}.png`
  return {
    fullSizeRelPath: url,
    fullSizePath: url,
    thumbRelPath: url,
    thumbnailPath: url
  }
}

export const exampleAvatarItems: AvatarItemInfo[] = [
  { id: 1, key: 'eyes1', name: 'Test Eyes', type: 'eyes', cost: 0, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.face, colors: ['', 'eyeColor', 'skinColor'], image: fakeImageObject('3xTest/eyes@3x') }] }, colors: [], hidden: false, image_version: 0, sort_order: 1 },
  { id: 3, key: 'background2', name: 'Chevron Background', type: 'background', cost: 2000000, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.background, colors: [], image: fakeImageObject('3xTest/BACK - blank@3x') }] }, colors: [], hidden: false, image_version: 0, sort_order: 2 },
  { id: 4, key: 'extra1', name: 'Wheelchair', type: 'extra', cost: 0, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.background + 1, colors: [], image: fakeImageObject('3xTest/ACCESSORIES - Wheelchair@3x') }] }, colors: [], hidden: false, image_version: 0, sort_order: 3 },
  { id: 5, key: 'face1', name: 'Aviators', type: 'face', cost: 200, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.face + 1, colors: [], image: fakeImageObject('3xTest/ACCESSORIES - glasses square@3x') }] }, colors: [], hidden: false, image_version: 0, sort_order: 4 },
  { id: 6, key: 'facialhair1', name: 'Beard', type: 'facialhair', cost: 0, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.face, colors: ['hairColor'], image: fakeImageObject('3xTest/FACIAL HAIR - 05@3x') }] }, colors: [], hidden: false, image_version: 0, sort_order: 5 },
  { id: 7, key: 'hair1', name: 'Long & Curly', type: 'hair', cost: 0, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.hairback, colors: ['hairColor'], image: fakeImageObject('3xTest/HAIR - 26@3x') }, { id: 2, layer: AvatarLayers.hairfront, colors: ['hairColor'], image: fakeImageObject('3xTest/HAIR - 26@3x') }] }, colors: ['hairColor'], image: fakeImageObject('hair1_shop'), hidden: false, image_version: 0, sort_order: 6 },
  // { id: 7, key: 'hair1', name: 'Long & Curly', type: 'hair', cost: 0, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.hairback, colors: ['hairColor'], image: fakeImageObject('hair1_1') }, { id: 2, layer: AvatarLayers.hairfront, colors: ['hairColor'], image: fakeImageObject('hair1_2') }] }, colors: ['hairColor'], image: fakeImageObject('hair1_shop'), hidden: false, image_version: 0 },
  { id: 9, key: 'hand1', name: 'Guitar', type: 'hand', cost: 1000, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.held, colors: [], image: fakeImageObject('3xTest/HOBBIES - guitar@3x') }] }, colors: [], hidden: false, image_version: 0, sort_order: 7 },
  { id: 10, key: 'hat1', name: 'Bobble Hat', type: 'hat', cost: 20000, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.hat, colors: [], image: fakeImageObject('3xTest/HEADSCARF - 01@3x') }] }, colors: [], hidden: false, image_version: 0, sort_order: 8 },
  { id: 12, key: 'jewellery1', name: 'Necklace', type: 'jewellery', cost: 100000, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.body + 1, colors: [], image: fakeImageObject('3xTest/JEWELLERY - 02@3x') }] }, colors: [], hidden: false, image_version: 0, sort_order: 9 },
  { id: 13, key: 'mouth1', name: 'Smile', type: 'mouth', cost: 0, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.face, colors: [], image: fakeImageObject('3xTest/MOUTH - happy@3x') }] }, colors: [], hidden: false, image_version: 0, sort_order: 10 },
  { id: 15, key: 'pet1', name: 'David', type: 'pet', cost: 1000, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.pet, colors: [], image: fakeImageObject('3xTest/PET - lizard@3x') }] }, colors: [], hidden: false, image_version: 0, sort_order: 11 },
  { id: 17, key: 'shirt1', name: 'Striped Shirt', type: 'shirt', cost: 0, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.body, colors: ['shirtColor1', 'shirtColor2', 'skinColor'], image: fakeImageObject('3xTest/shirt@3x') }] }, colors: [], hidden: false, image_version: 0, sort_order: 12 }
  // { id: 19, key: 'wings1', name: 'Bee Wings', type: 'wings', cost: 833, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.background + 1, colors: [], image: fakeImageObject('wings1') }] }, colors: [], hidden: false, image_version: 0 },
]
// export const exampleAvatarItems: AvatarItemInfo[] = [
//   { id: 1, key: 'eyes1', name: 'Test Eyes', type: 'eyes', cost: 0, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.face, colors: ['', 'eyeColor', 'skinColor'], image: fakeImageObject('eyes1') }] }, colors: [], hidden: false, image_version: 0 },
//   { id: 2, key: 'background1', name: 'Custom Background', type: 'background', cost: 0, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.background, colors: ['backgroundColor'], image: fakeImageObject('background1') }] }, colors: [], hidden: false, image_version: 0 },
//   { id: 3, key: 'background2', name: 'Chevron Background', type: 'background', cost: 2000000, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.background, colors: [], image: fakeImageObject('background2') }] }, colors: [], hidden: false, image_version: 0 },
//   { id: 4, key: 'extra1', name: 'Wheelchair', type: 'extra', cost: 0, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.background + 1, colors: [], image: fakeImageObject('extra1') }] }, colors: [], hidden: false, image_version: 0 },
//   { id: 5, key: 'face1', name: 'Aviators', type: 'face', cost: 200, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.face + 1, colors: [], image: fakeImageObject('face1') }] }, colors: [], hidden: false, image_version: 0 },
//   { id: 6, key: 'facialhair1', name: 'Beard', type: 'facialhair', cost: 0, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.face, colors: ['hairColor'], image: fakeImageObject('facialhair1') }] }, colors: [], hidden: false, image_version: 0 },
//   { id: 7, key: 'hair1', name: 'Long & Curly', type: 'hair', cost: 0, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.hairback, colors: ['hairColor'], image: fakeImageObject('hair1_1') }, { id: 2, layer: AvatarLayers.hairfront, colors: ['hairColor'], image: fakeImageObject('hair1_2') }] }, colors: ['hairColor'], image: fakeImageObject('hair1_shop'), hidden: false, image_version: 0 },
//   { id: 8, key: 'hair2', name: 'Short & Parted', type: 'hair', cost: 0, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.hairfront, colors: ['hairColor'], image: fakeImageObject('hair2') }] }, colors: [], hidden: false, image_version: 0 },
//   { id: 9, key: 'hand1', name: 'Guitar', type: 'hand', cost: 1000, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.held, colors: [], image: fakeImageObject('hand1') }] }, colors: [], hidden: false, image_version: 0 },
//   { id: 10, key: 'hat1', name: 'Bobble Hat', type: 'hat', cost: 20000, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.hat, colors: [], image: fakeImageObject('hat1') }] }, colors: [], hidden: false, image_version: 0 },
//   { id: 11, key: 'hat2', name: 'Head Cover', type: 'hat', cost: 100, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.hat + 1, colors: ['headwearColor'], image: fakeImageObject('hat2') }] }, colors: [], hidden: false, image_version: 0 },
//   { id: 12, key: 'jewellery1', name: 'Necklace', type: 'jewellery', cost: 100000, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.body + 1, colors: [], image: fakeImageObject('jewellery1') }] }, colors: [], hidden: false, image_version: 0 },
//   { id: 13, key: 'mouth1', name: 'Smile', type: 'mouth', cost: 0, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.face, colors: [], image: fakeImageObject('mouth1') }] }, colors: [], hidden: false, image_version: 0 },
//   { id: 14, key: 'mouth2', name: 'Grimace', type: 'mouth', cost: 0, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.face, colors: [], image: fakeImageObject('mouth2') }] }, colors: [], hidden: false, image_version: 0 },
//   { id: 15, key: 'pet1', name: 'David', type: 'pet', cost: 1000, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.pet, colors: [], image: fakeImageObject('pet1') }] }, colors: [], hidden: false, image_version: 0 },
//   { id: 16, key: 'scarf1', name: 'Scarf', type: 'scarf', cost: 0, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.body + 1, colors: [], image: fakeImageObject('scarf1') }] }, colors: [], hidden: false, image_version: 0 },
//   { id: 17, key: 'shirt1', name: 'Striped Shirt', type: 'shirt', cost: 0, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.body, colors: ['shirtColor1', 'shirtColor2', 'skinColor'], image: fakeImageObject('shirt1') }] }, colors: [], hidden: false, image_version: 0 },
//   { id: 18, key: 'shirt2', name: 'Sport Shirt', type: 'shirt', cost: 1010, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.body, colors: ['', 'skinColor'], image: fakeImageObject('shirt2') }] }, colors: [], hidden: false, image_version: 0 },
//   { id: 19, key: 'wings1', name: 'Bee Wings', type: 'wings', cost: 833, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.background + 1, colors: [], image: fakeImageObject('wings1') }] }, colors: [], hidden: false, image_version: 0 },
//   { id: 20, key: 'pet2', name: 'David', type: 'pet', cost: 1000, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.pet, colors: [], image: fakeImageObject('pet2') }] }, colors: [], hidden: false, image_version: 0 },
//   { id: 21, key: 'pet3', name: 'David', type: 'pet', cost: 1000, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.pet, colors: [], image: fakeImageObject('pet3') }] }, colors: [], hidden: false, image_version: 0 },
//   { id: 22, key: 'pet4', name: 'David', type: 'pet', cost: 1000, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.pet, colors: [], image: fakeImageObject('pet4') }] }, colors: [], hidden: false, image_version: 0 },
//   { id: 23, key: 'pet5', name: 'David', type: 'pet', cost: 1000, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.pet, colors: [], image: fakeImageObject('pet5') }] }, colors: [], hidden: false, image_version: 0 },
//   { id: 24, key: 'pet6', name: 'David', type: 'pet', cost: 5000, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.pet, colors: [], image: fakeImageObject('pet6') }] }, colors: [], hidden: false, image_version: 0 },
//   { id: 25, key: 'pet7', name: 'David', type: 'pet', cost: 5000, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.pet, colors: [], image: fakeImageObject('pet7') }] }, colors: [], hidden: false, image_version: 0 },
//   { id: 26, key: 'pet8', name: 'David', type: 'pet', cost: 8000, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.pet, colors: [], image: fakeImageObject('pet8') }] }, colors: [], hidden: false, image_version: 0 },
//   { id: 27, key: 'pet9', name: 'David', type: 'pet', cost: 8000, layers: { max_id: 10, layers: [{ id: 1, layer: AvatarLayers.pet, colors: [], image: fakeImageObject('pet9') }] }, colors: [], hidden: false, image_version: 0 },
// ]

export const testAvatarData: UserAvatar = {
  // color settings
  backgroundColor: 'col4',
  hairColor: 'hair1',
  eyeColor: 'eye1',
  skinColor: 'skin1',
  shirtColor1: 'col1',
  shirtColor2: 'col2',
  headwearColor: 'col3'

  // items
  // background: exampleAvatarItems[1],
  // hair: exampleAvatarItems[6],
  // eyes: exampleAvatarItems[0],
  // mouth: exampleAvatarItems[12],
  // facepaint: undefined,
  // facialhair: exampleAvatarItems[5],
  // face: exampleAvatarItems[4],
  // jewellery: exampleAvatarItems[11],
  // hat: exampleAvatarItems[9],
  // shirt: exampleAvatarItems[16],
  // scarf: exampleAvatarItems[15],
  // wings: exampleAvatarItems[18],
  // hand: exampleAvatarItems[8],
  // pet: exampleAvatarItems[14],
  // extra: exampleAvatarItems[3]
}

export const AvatarItemLogActions = ['create', 'update', 'delete', 'sort_order'] as const
export type AvatarItemLogActions = typeof AvatarItemLogActions[number]

export function validateLegacyAvatar (avatar: UserAvatar) {
  if (avatar.background && (legacyAvatarItems.backgroundType as readonly string[]).includes(avatar.background.key)) { return false }
  if (avatar.shirt && (legacyAvatarItems.shirtType as readonly string[]).includes(avatar.shirt.key)) { return false }
  if (avatar.face && (legacyAvatarItems.face as readonly string[]).includes(avatar.face.key)) { return false }
  if (avatar.background && (legacyAvatarItems.backgroundType as readonly string[]).includes(avatar.background.key)) { return false }
  return true
}

export function getAvatarItemTypeName (type: AvatarItemSlot) {
  switch (type) {
    case 'facialhair': return 'Facial Hair'
    case 'face': return 'Face Accessory'
    case 'hand': return 'Held Item'
    default: return `${type.charAt(0).toUpperCase()}${type.slice(1)}`
  }
}
