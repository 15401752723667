import { ArrayElement } from '../api'
import { CombinedAvatarItemType } from './avatarItems'

export const PurchasableItemType = ['shirtType', 'backgroundType', 'accessory', 'face'] as const
export type PurchasableItemType = ArrayElement<typeof PurchasableItemType>

export interface PurchasableItem {
  type: CombinedAvatarItemType
  name: string
  key: string
  cost: number
}

export const purchasableItems: PurchasableItem[] = [
  { type: 'shirtType', name: 'Striped Shirt', key: 'stripe', cost: 10 },
  { type: 'shirtType', name: 'Striped Shirt', key: 'stripe2', cost: 10 },
  { type: 'shirtType', name: 'Hexagon Shirt', key: 'hex', cost: 10 },
  { type: 'shirtType', name: 'Pocket Shirt', key: 'pocket', cost: 10 },
  { type: 'shirtType', name: 'Circle Shirt', key: 'circle', cost: 10 },
  { type: 'shirtType', name: 'Vee Neck Shirt', key: 'vee', cost: 10 },
  { type: 'shirtType', name: 'Wide Neck Shirt', key: 'wide', cost: 10 },
  { type: 'shirtType', name: 'Zip Shirt', key: 'zip', cost: 10 },
  { type: 'shirtType', name: 'Buttoned Shirt', key: 'buttons', cost: 25 },
  { type: 'shirtType', name: 'Buttoned Shirt', key: 'buttons2', cost: 25 },
  { type: 'shirtType', name: 'Collar Shirt', key: 'collar', cost: 25 },
  { type: 'shirtType', name: 'Vee Neck Collar Shirt', key: 'veeCollar', cost: 25 },
  { type: 'shirtType', name: 'Collar Buttoned Shirt', key: 'collarButtons', cost: 25 },
  { type: 'shirtType', name: 'ZigZag Shirt', key: 'zigZag', cost: 25 },
  { type: 'shirtType', name: 'Wave Shirt', key: 'wave', cost: 25 },
  { type: 'shirtType', name: 'Pull String Shirt', key: 'pullStrings', cost: 50 },
  { type: 'shirtType', name: 'Tassles Shirt', key: 'tassles', cost: 50 },
  { type: 'shirtType', name: 'Flower Shirt', key: 'shirtflower', cost: 50 },
  { type: 'shirtType', name: 'Pearls Shirt', key: 'shirtpearls', cost: 50 },
  { type: 'shirtType', name: 'Mid-Stripe Shirt', key: 'shirtmidstripe', cost: 50 },
  { type: 'shirtType', name: 'Frilled Collar Shirt', key: 'shirtvfrills', cost: 50 },
  { type: 'shirtType', name: 'ZigZag Shirt', key: 'shirthzigzag', cost: 50 },
  { type: 'shirtType', name: 'Shawl Shirt', key: 'shirthijab', cost: 50 },
  { type: 'shirtType', name: 'Stiches Shirt', key: 'shirthalloween', cost: 50 },
  { type: 'shirtType', name: 'Bowtie Shirt', key: 'shirtbowtie', cost: 50 },
  { type: 'shirtType', name: 'Tie Shirt', key: 'shirttie', cost: 50 },
  { type: 'shirtType', name: 'Check Shirt', key: 'shirtcheck', cost: 50 },
  { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball1', cost: 250 },
  { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball2', cost: 250 },
  { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball3', cost: 250 },
  { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball4', cost: 250 },
  { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball5', cost: 250 },
  { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball6', cost: 250 },
  { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball7', cost: 250 },
  { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball8', cost: 250 },
  { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball9', cost: 250 },
  { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball10', cost: 250 },
  { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball11', cost: 250 },
  { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball12', cost: 250 },
  { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball13', cost: 250 },
  { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball14', cost: 250 },
  { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball15', cost: 250 },
  { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball16', cost: 250 },
  { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball17', cost: 250 },
  { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball18', cost: 250 },
  { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball19', cost: 250 },
  { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball20', cost: 250 },
  { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball21', cost: 250 },
  { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball22', cost: 250 },
  { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball23', cost: 250 },
  { type: 'shirtType', name: 'Football Shirt', key: 'shirtfootball24', cost: 250 },
  { type: 'backgroundType', name: 'Pattern Background', key: 'pattern1', cost: 500 },
  { type: 'backgroundType', name: 'Pattern Background', key: 'pattern2', cost: 500 },
  { type: 'backgroundType', name: 'Pattern Background', key: 'pattern3', cost: 500 },
  { type: 'backgroundType', name: 'Pattern Background', key: 'pattern4', cost: 500 },
  { type: 'backgroundType', name: 'Pattern Background', key: 'pattern5', cost: 500 },
  { type: 'backgroundType', name: 'Pattern Background', key: 'pattern6', cost: 500 },
  { type: 'backgroundType', name: 'Pattern Background', key: 'pattern7', cost: 500 },
  { type: 'backgroundType', name: 'Pattern Background', key: 'pattern8', cost: 500 },
  { type: 'backgroundType', name: 'Pattern Background', key: 'pattern9', cost: 500 },
  { type: 'backgroundType', name: 'Pattern Background', key: 'pattern10', cost: 500 },
  { type: 'backgroundType', name: 'Sea Background', key: 'sea', cost: 1000 },
  { type: 'backgroundType', name: 'Desert Background', key: 'desert', cost: 1000 },
  { type: 'backgroundType', name: 'Beach Background', key: 'beach', cost: 1000 },
  { type: 'backgroundType', name: 'Space Background', key: 'space', cost: 1000 },
  { type: 'backgroundType', name: 'Forest Background', key: 'forest', cost: 1000 },
  { type: 'backgroundType', name: 'Mountains Background', key: 'mountains', cost: 1000 },
  { type: 'backgroundType', name: 'Egypt Background', key: 'egypt', cost: 1000 },
  { type: 'backgroundType', name: 'Castle Background', key: 'castle', cost: 1000 },
  { type: 'backgroundType', name: 'Hearts Background', key: 'hearts', cost: 1000 },
  { type: 'backgroundType', name: 'Rainbow Background', key: 'rainbow', cost: 1000 },
  { type: 'backgroundType', name: 'Snowy Background', key: 'snowy', cost: 1000 },
  { type: 'backgroundType', name: 'Sea Background', key: 'sea2', cost: 1000 },
  { type: 'backgroundType', name: 'Zodiac Background', key: 'zodiac', cost: 1000 },
  { type: 'backgroundType', name: 'Art Background', key: 'art', cost: 1000 },
  { type: 'backgroundType', name: 'World Background', key: 'world', cost: 1000 },
  { type: 'backgroundType', name: 'World Background', key: 'world2', cost: 1000 },
  { type: 'backgroundType', name: 'Map Background', key: 'map', cost: 1000 },
  { type: 'backgroundType', name: 'Music Background', key: 'music', cost: 1000 },
  { type: 'backgroundType', name: 'Romans Background', key: 'romans', cost: 1000 },
  { type: 'backgroundType', name: 'Chemistry Background', key: 'chemistry', cost: 2000 },
  { type: 'backgroundType', name: 'Football Background', key: 'football', cost: 2000 },
  { type: 'backgroundType', name: 'Balloons Background', key: 'balloons', cost: 2000 },
  { type: 'accessory', name: 'Dark Shades', key: 'darkShades', cost: 500 },
  { type: 'face', name: 'Round Glasses', key: 'roundGlasses', cost: 500 },
  { type: 'face', name: 'Angled Glasses', key: 'angledGlasses', cost: 500 },
  { type: 'face', name: 'Cool Shades', key: 'coolShades', cost: 500 },
  { type: 'face', name: 'Dark Shades', key: 'darkShades', cost: 500 },
  { type: 'face', name: 'Cat Mask', key: 'catMask', cost: 2000 },
  { type: 'face', name: 'Puppy Mask', key: 'puppyMask', cost: 2000 },
  { type: 'face', name: 'Panda Mask', key: 'pandaMask', cost: 2000 },
  { type: 'face', name: 'Disguise Mask', key: 'disguiseMask', cost: 2000 },
  { type: 'face', name: 'Superhero Mask', key: 'batmanMask', cost: 2000 },
  { type: 'face', name: 'Scary Mask', key: 'scaryMask', cost: 2000 },
  { type: 'face', name: 'Eye Patch', key: 'eyePatch', cost: 2000 },
  { type: 'face', name: 'Snorkel', key: 'snorkel', cost: 10000 },
  { type: 'face', name: 'Lightning', key: 'facelightning', cost: 1000 },
  { type: 'face', name: 'Mouse Nose', key: 'facemousenose', cost: 500 },
  { type: 'face', name: 'Gold Hoop Earrings', key: 'goldhoopearrings', cost: 100 },
  { type: 'face', name: 'Square Glasses', key: 'squareglasses', cost: 0 },
  { type: 'face', name: 'Round Glasses', key: 'roundglasseswhite', cost: 0 },
  { type: 'face', name: 'Beard', key: 'beardsmall', cost: 500 },
  { type: 'face', name: 'Mustache', key: 'longmustache', cost: 500 },
  { type: 'face', name: 'Lines Glasses', key: 'glasseslines', cost: 500 },
  { type: 'face', name: 'Bee Wings', key: 'wingsbee', cost: 50000 },
  { type: 'face', name: 'Wand', key: 'wand', cost: 5000 },
  { type: 'face', name: 'Pig Mask', key: 'pigMask', cost: 2000 },
  { type: 'face', name: 'Elephant Mask', key: 'elephantMask', cost: 5000 },
  { type: 'face', name: 'Giraffe Mask', key: 'giraffeMask', cost: 2000 },
  { type: 'face', name: 'Lion Mask', key: 'lionMask', cost: 5000 },
  { type: 'face', name: 'Tiger Mask', key: 'tigerMask', cost: 2000 },
  { type: 'face', name: 'Reindeer Mask', key: 'reindeerMask', cost: 10000 },
  { type: 'face', name: 'Zebra Mask', key: 'zebraMask', cost: 2000 },
  { type: 'face', name: 'Dog Mask', key: 'dogMask', cost: 2000 },
  { type: 'face', name: 'Unicorn Mask', key: 'unicornMask', cost: 10000 },
  { type: 'face', name: 'Monkey Mask', key: 'monkeyMask', cost: 2000 },
  { type: 'accessory', name: 'Flower Headband', key: 'flowerHairband', cost: 100 },
  { type: 'accessory', name: 'Star Headband', key: 'starHeadband', cost: 100 },
  { type: 'accessory', name: 'Green Headband', key: 'greenHeadband', cost: 100 },
  { type: 'accessory', name: 'Orange Stripy Headband', key: 'orangeHeadband', cost: 100 },
  { type: 'accessory', name: 'Pink Headphones', key: 'pinkHeadphones', cost: 500 },
  { type: 'accessory', name: 'Green Headphones', key: 'greenHeadphones', cost: 500 },
  { type: 'accessory', name: 'Star Cap', key: 'pinkCap', cost: 1000 },
  { type: 'accessory', name: 'Blue Cap', key: 'blueCap', cost: 1000 },
  { type: 'accessory', name: 'Wooly Hat', key: 'blueTassleHat', cost: 1000 },
  { type: 'accessory', name: 'Pink Wooly Hat', key: 'pinkWoolyHat', cost: 1000 },
  { type: 'accessory', name: 'Blue Wooly Hat', key: 'blueWoolyHat', cost: 1000 },
  { type: 'accessory', name: 'Green Wooly Hat', key: 'greenWoolyHat', cost: 1000 },
  { type: 'accessory', name: 'Pink Scarf', key: 'pinkScarf', cost: 1000 },
  { type: 'accessory', name: 'Blue Scarf', key: 'blueScarf', cost: 1000 },
  { type: 'accessory', name: 'Head Scarf', key: 'headScarf', cost: 2000 },
  { type: 'accessory', name: 'Jester Hat', key: 'jesterHat', cost: 5000 },
  { type: 'accessory', name: 'Pirate Hat', key: 'pirateHat', cost: 5000 },
  { type: 'accessory', name: 'Astronaut Helmet', key: 'astronaut', cost: 10000 },
  { type: 'accessory', name: 'Sombrero', key: 'sombrero', cost: 10000 },
  { type: 'accessory', name: 'Crown', key: 'crown', cost: 25000 },
  { type: 'accessory', name: 'Red Flower', key: 'redflower', cost: 100 },
  { type: 'accessory', name: 'Laurel', key: 'laurel', cost: 1000 },
  { type: 'accessory', name: 'Wizard Hat', key: 'wizardhat', cost: 10000 },
  { type: 'accessory', name: 'Green Hair Bow', key: 'greenhairbow', cost: 100 },
  { type: 'accessory', name: 'Cowboy Hat', key: 'cowboyhat', cost: 5000 },
  { type: 'accessory', name: 'Witch Hat', key: 'witchhat', cost: 10000 },
  { type: 'accessory', name: 'Rabbit Ears', key: 'rabbitearsheadband', cost: 2000 },
  { type: 'accessory', name: 'Small Green Hair Bows', key: 'smallgreenbows', cost: 100 },
  { type: 'accessory', name: 'Hair Pearls', key: 'hairpearls', cost: 100 },
  { type: 'accessory', name: 'Viking Helm', key: 'helmetviking', cost: 25000 },
  { type: 'accessory', name: 'Pharaoh Mask', key: 'helmetegypt', cost: 25000 },
  { type: 'accessory', name: 'Red Beret', key: 'hatberetred', cost: 1000 },
  { type: 'accessory', name: 'Backwards Cap', key: 'hatcapbackwards', cost: 1000 },
  { type: 'accessory', name: 'Small Cap', key: 'hatkippah', cost: 100 },
  { type: 'accessory', name: 'Santa Hat', key: 'hatsanta', cost: 25000 },
  { type: 'accessory', name: 'Chef Hat', key: 'hatchef', cost: 10000 },
  { type: 'accessory', name: 'Bee Headband', key: 'headbandbee', cost: 50000 }
]

export function getPurchasableItemCost (key: string): number | null {
  const accessory = purchasableItems.find(purchasable => purchasable.key === key)

  if (!accessory) {
    return null
  }

  return accessory.cost
}
