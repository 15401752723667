



























import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import AvatarItem from './AvatarItem.vue'
import { AvatarItemInfo, AvatarItemForShop } from './avatarItems'
import { AvatarRenderer, CanvasForUseInAvatarRendering } from './avatarRenderer'

@Component({
  components: {
    AvatarItem
  }
})
export default class AvatarShopItem extends mixins() {
  @Prop({ required: true }) item!: AvatarItemForShop
  @Prop({ default: null }) _renderer!: AvatarRenderer

  public renderer: AvatarRenderer | null = null

  mounted () {
    if (!this.item) {
      console.error('No avatar item given to shop item')
      return
    }
    if (!this._renderer) {
      const newCanvas = () => {
        const c = document.createElement('canvas') as HTMLCanvasElement
        const context = c.getContext('2d')!
        const canvas = c as CanvasForUseInAvatarRendering
        return { canvas, context }
      }

      const newImage = () => {
        return new Image()
      }
      this.renderer = new AvatarRenderer(newCanvas, newImage, true)
    } else {
      this.renderer = this._renderer
    }
    this.updateImage()
  }

  private itemIsCustomisable (item: AvatarItemInfo) {
    if (!item.layers.layers.filter(x => !!x.colors.length).length) { return false }
    if (item.type === 'hair' || item.type === 'facialhair') {
      return !!item.layers.layers.filter(x => !!x.colors.filter(y => y !== 'hairColor' && y !== '' && y !== 'skinColor').length).length
    }
    if (item.type === 'eyes') {
      return !!item.layers.layers.filter(x => !!x.colors.filter(y => y !== 'eyeColor' && y !== '' && y !== 'skinColor').length).length
    }
    return !!item.layers.layers.filter(x => !!x.colors.filter(y => y !== '' && y !== 'skinColor').length).length
  }

  private onAvatarItemClick () {
    this.$emit('click')
  }

  public updateImage () {
    const elem = this.$refs.item as AvatarItem
    if (elem) {
      elem.updateImage()
    }
  }
}
