



















import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { AvatarItemInfo } from './avatarItems'
import { AvatarRenderer, CanvasForUseInAvatarRendering } from './avatarRenderer'

@Component({
  components: {
  }
})
export default class AvatarItem extends mixins() {
  @Prop({ required: true }) item!: AvatarItemInfo
  @Prop({ default: false }) expandImage!: boolean
  @Prop({ default: null }) _renderer!: AvatarRenderer

  public renderer!: AvatarRenderer

  private context!: CanvasRenderingContext2D

  public src = ''

  mounted () {
    if (!this.item) {
      console.error('No avatar item given to thumbnail renderer')
      return
    }
    if (!this.item.image && !this.item.new_image) {
      console.error('Item does not have a shop image')
      return
    }
    if (!this._renderer) {
      const newCanvas = () => {
        const c = document.createElement('canvas') as HTMLCanvasElement
        const context = c.getContext('2d')!
        const canvas = c as CanvasForUseInAvatarRendering
        return { canvas, context }
      }

      const newImage = () => {
        return new Image()
      }
      this.renderer = new AvatarRenderer(newCanvas, newImage, true)
    } else {
      this.renderer = this._renderer
    }
    // this.renderer.addItem(this.item)
    this.updateImage()
  }

  public async updateImage () {
    const src = await this.renderer.getItemShopImage(this.item) as HTMLCanvasElement
    const img = this.$refs.img as HTMLCanvasElement
    if (img) {
      if (!this.context) {
        this.context = img.getContext('2d', { willReadFrequently: true }) as CanvasRenderingContext2D
      }
      this.context!.clearRect(0, 0, img.width, img.height)
      img.width = src.width
      img.height = src.height
      this.context!.drawImage(src, 0, 0)
    }
    // const img = this.$refs.img as HTMLImageElement
    // if (img) {
    //   img.src = this.imgSrc
    // }
  }

  get imgSrc () {
    return this.src || 'https://files.edshed.com/img/avatar/avatar_loading.png'
  }
}
