import _ from 'lodash'
import { AvatarItemSlot, AvatarColor, AvatarItemInfo, AvatarLayers, UserAvatar, getAvatarColorHex, avatarImageDimensions, _AvatarItemSlot } from './avatarItems'
import { avatarToHash } from './avatarUtils'

export type CanvasForUseInAvatarRendering = {
  width: number,
  height: number,
  toDataURL (): string
}

interface AvatarLayer {
  key: string
  itemLayerIndex: number // e.g. hair may have two layer indexes
  layer: number
  active: boolean // So images can be stored when switching between outfits
  image: HTMLImageElement
  imageData?: ImageData
  type: AvatarItemSlot
  colors: AvatarColor[]
}

// When two items share the same layer value, use this to infer which gets drawn first
const drawPriority = [..._AvatarItemSlot]

type AvatarItemCallback = (data: CanvasForUseInAvatarRendering) => void
type AvatarCallback = (data: CanvasForUseInAvatarRendering) => void
type Rejection = (reason?: any) => void

export class AvatarRenderer {
  private defaultImgUrl = 'https://files.edshed.com/img/avatar/default/'

  private _skinColor = '#F2E5C9'
  public get skinColor () { return this._skinColor }
  public set skinColor (v: string) { this._skinColor = v; this.onColorChange() }

  private _hairColor = '#F8F875'
  public get hairColor () { return this._hairColor }
  public set hairColor (v: string) { this._hairColor = v; this.onColorChange() }

  private _eyeColor = '#575756'
  public get eyeColor () { return this._eyeColor }
  public set eyeColor (v: string) { this._eyeColor = v; this.onColorChange() }

  private _shirtColor1 = '#1A54FA'
  public get shirtColor1 () { return this._shirtColor1 }
  public set shirtColor1 (v: string) { this._shirtColor1 = v; this.onColorChange() }

  private _shirtColor2 = '#69cdb1'
  public get shirtColor2 () { return this._shirtColor2 }
  public set shirtColor2 (v: string) { this._shirtColor2 = v; this.onColorChange() }

  private _backgroundColor = '#C6C6C6'
  public get backgroundColor () { return this._backgroundColor }
  public set backgroundColor (v: string) { this._backgroundColor = v; this.onColorChange() }

  private _headwearColor = '#f2ab30'
  public get headwearColor () { return this._headwearColor }
  public set headwearColor (v: string) { this._headwearColor = v; this.onColorChange() }

  public width = avatarImageDimensions.width
  public height = avatarImageDimensions.height

  public canvas!: CanvasForUseInAvatarRendering
  public context!: CanvasRenderingContext2D

  private layers: AvatarLayer[] = []

  private shopItems: { key: string, image: HTMLImageElement, imageData?: ImageData }[] = []
  private cachedShopImages: { [key: string]: CanvasForUseInAvatarRendering } = {}

  public loading = true

  public isAuthoringMode = false

  public noCache = false

  public retryLimit = 100

  private getNewCanvas: () => { canvas: CanvasForUseInAvatarRendering, context: CanvasRenderingContext2D }
  private getNewImage: () => HTMLImageElement

  /**
   * @param canvasCreationFunction - An external function to create a new canvas and context object depending on the compatability of the environment
   * @param imageCreationFunction - An external function to create a new image object depending on the compatability of the environment
   * @param lite - Option bool : when true will avoid downloading the default avatar items
   */
  constructor (canvasCreationFunction: () => { canvas: CanvasForUseInAvatarRendering, context: CanvasRenderingContext2D }, imageCreationFunction: () => HTMLImageElement, lite?: boolean, noCache?: boolean) {
    this.getNewCanvas = canvasCreationFunction
    this.getNewImage = imageCreationFunction

    const { canvas, context } = this.getNewCanvas()

    this.canvas = canvas
    this.canvas.width = this.width
    this.canvas.height = this.height
    this.context = context

    this.noCache = noCache || false
    
    if (!lite) {
      // common images
      const head = this.getImage(`${this.defaultImgUrl}head.png`)
      this.layers.push({ key: 'head', image: head, layer: AvatarLayers.head, active: true, type: 'default', colors: ['skinColor'], itemLayerIndex: 0 })
      const ears = this.getImage(`${this.defaultImgUrl}ears.png`)
      this.layers.push({ key: 'ears', image: ears, layer: AvatarLayers.hairback - 1, active: true, type: 'default', colors: ['skinColor'], itemLayerIndex: 0 })
      const nose = this.getImage(`${this.defaultImgUrl}nose.png`)
      this.layers.push({ key: 'nose', image: nose, layer: AvatarLayers.face, active: true, type: 'default', colors: ['skinColor'], itemLayerIndex: 0 })
      const background = this.getImage(`${this.defaultImgUrl}background.png`)
      this.layers.push({ key: 'debugBackground', image: background, layer: AvatarLayers.background, active: true, type: 'background', colors: ['backgroundColor'], itemLayerIndex: 0 })
      const eyes = this.getImage(`${this.defaultImgUrl}eyes.png`)
      this.layers.push({ key: 'debugEyes', image: eyes, layer: AvatarLayers.face, active: true, type: 'eyes', colors: ['', 'eyeColor', 'skinColor'], itemLayerIndex: 0 })
      const mouth = this.getImage(`${this.defaultImgUrl}mouth.png`)
      this.layers.push({ key: 'debugMouth', image: mouth, layer: AvatarLayers.face, active: true, type: 'mouth', colors: [], itemLayerIndex: 0 })
      const hair = this.getImage(`${this.defaultImgUrl}hair.png`)
      this.layers.push({ key: 'debugHair', image: hair, layer: AvatarLayers.hairfront, active: true, type: 'hair', colors: ['hairColor'], itemLayerIndex: 0 })
      const top = this.getImage(`${this.defaultImgUrl}shirt.png`)
      this.layers.push({ key: 'debugTop', image: top, layer: AvatarLayers.shirt, active: true, type: 'shirt', colors: ['shirtColor1', 'skinColor'], itemLayerIndex: 0 })
    }
  }

  /**
   * ASYNC REQUEST METHODS
   */

  public getDebugAvatarImageURL (): Promise<CanvasForUseInAvatarRendering> {
    return new Promise((resolve, reject) => {
      // const promise_id = `getDebugAvatarImageURL-${Math.floor(Math.random() * 100)}`
      // const timeout = setTimeout(() => reject('Image Load Timeout - ' + promise_id), 10000)
      const listener = (data: CanvasForUseInAvatarRendering) => {
        // console.log(promise_id, ' - resolved')
        // clearTimeout(timeout)
        resolve(data)
      }
      this.drawDebugAvatar()
      this.drawAvatar(listener)
    })
  }

  public getAvatarImageURL (): Promise<CanvasForUseInAvatarRendering> {
    return new Promise((resolve, reject) => {
      const promise_id = `getAvatarImageURL-${Math.floor(Math.random() * 100)}`
      const timeout = setTimeout(() => reject('Image Load Timeout - ' + promise_id), 10000)
      const listener = (data: CanvasForUseInAvatarRendering) => {
        // console.log(promise_id, ' - resolved')
        clearTimeout(timeout)
        resolve(data)
      }
      this.drawAvatar(listener)
    })
  }

  public getSingleItemCanvas (item: AvatarItemInfo): Promise<CanvasForUseInAvatarRendering> {
    return new Promise((resolve, reject) => {
      const promise_id = `getSingleItemCanvas-${Math.floor(Math.random() * 100)}`
      const timeout = setTimeout(() => reject('Image Load Timeout - ' + promise_id), 10000)
      const listener = (data: CanvasForUseInAvatarRendering) => {
        // console.log(promise_id, ' - resolved')
        clearTimeout(timeout)
        resolve(data)
      }
      const rejection = (err: string) => {
        console.error(promise_id, ' - rejected')
        clearTimeout(timeout)
        reject(err)
      }
      this.drawSingleItem(item, listener, rejection)
    })
  }

  public getItemShopImage (item: AvatarItemInfo): Promise<CanvasForUseInAvatarRendering> {
    return new Promise((resolve, reject) => {
      const promise_id = `getItemShopImage-${Math.floor(Math.random() * 100)}`
      const timeout = setTimeout(() => reject('Image Load Timeout - ' + promise_id), 10000)
      const listener = (data: CanvasForUseInAvatarRendering) => {
        // console.log(promise_id, ' - resolved')
        clearTimeout(timeout)
        resolve(data)
      }
      const rejection = (err: string) => {
        console.error(promise_id, ' - rejected')
        clearTimeout(timeout)
        reject(err)
      }
      this.drawShopImage(item, listener, rejection)
    })
  }

  private onColorChange () {
    // Nothing happens
  }

  public resetToDefault () {
    this.drawDebugAvatar()
  }

  /**
   * PUBLIC SETUP METHODS
   */

  public addItem (item: AvatarItemInfo) {
    const { key, type } = item

    this.layers = this.layers.filter(x => x.key !== key) // remove old versions of this item

    item.layers.layers.forEach((itemLayer, index) => {
      if (!itemLayer.image && !itemLayer.new_image) { return }
      let imgUrl = ''
      if (itemLayer.image) {
        imgUrl = itemLayer.image.fullSizePath!
      } else if (itemLayer.new_image) {
        imgUrl = itemLayer.new_image.data!
      }
      if (!imgUrl) { return }
      const layer = itemLayer.layer + 1
      const image = this.getImage(imgUrl)
      this.layers.push({ key, image, layer, itemLayerIndex: index, active: true, type, colors: itemLayer.colors })
    })

    this.setKeyAsActive(key)
  }

  public addItems (items: AvatarItemInfo[]) {
    for (let i = 0; i < items.length; i++) {
      this.addItem(items[i])
    }
  }

  public removeItem (item: AvatarItemInfo) {
    const layers = this.layers.filter(x => x.key === item.key)
    layers.forEach((x) => { x.active = false })
  }

  public import (avatar: UserAvatar) {
    this.layers.forEach((layer) => {
      if (layer.type !== 'default') {
        layer.active = false
      }
    })

    this.drawDebugAvatar()

    if (avatar.backgroundColor) {
      this.backgroundColor = getAvatarColorHex(avatar.backgroundColor)
    }
    if (avatar.skinColor) {
      this.skinColor = getAvatarColorHex(avatar.skinColor)
    }
    if (avatar.hairColor) {
      this.hairColor = getAvatarColorHex(avatar.hairColor)
    }
    if (avatar.eyeColor) {
      this.eyeColor = getAvatarColorHex(avatar.eyeColor)
    }
    if (avatar.shirtColor1) {
      this.shirtColor1 = getAvatarColorHex(avatar.shirtColor1)
    }
    if (avatar.shirtColor2) {
      this.shirtColor2 = getAvatarColorHex(avatar.shirtColor2)
    }
    if (avatar.headwearColor) {
      this.headwearColor = getAvatarColorHex(avatar.headwearColor)
    }

    for (const key in avatar) {
      if (key === 'extra') {
        const items: AvatarItemInfo[] = []
        for (const extra in avatar.extra) {
          items.push(avatar.extra[extra] as AvatarItemInfo)
        }
        items.forEach((extraItem) => {
          if (extraItem && extraItem.key) {
            this.addItem(extraItem)
          }
        })
      } else if (typeof (avatar as any)[key] === 'object') {
        const item = (avatar as any)[key] as AvatarItemInfo
        if (item && item.key) {
          this.addItem(item)
        }
      } else if (!(avatar as any)[key]) {
        const activeItem = this.layers.find(x => x.type === key && x.active)
        if (activeItem) {
          activeItem.active = false
        }
      }
    }
  }

  /**
   * PRIVATE METHODS
   */

  private drawDebugAvatar () {
    // Test avatar

    this.layers.forEach((x) => { x.active = false })

    this.setKeyAsActive('head')
    this.setKeyAsActive('ears')
    this.setKeyAsActive('nose')
    this.setKeyAsActive('debugBackground')
    this.setKeyAsActive('debugEyes')
    this.setKeyAsActive('debugMouth')
    this.setKeyAsActive('debugHair')
    this.setKeyAsActive('debugTop')
  }

  private drawAvatar (callback?: AvatarCallback) {
    const loadingImages = this.layers.filter(x => !x.image.width)
    if (loadingImages.length) {
      loadingImages.forEach((x) => {
        x.image.onload = () => this.drawAvatar(callback)
      })
    } else {
      this._drawAvatar(callback)
    }
  }

  private _drawAvatar (callback?: AvatarCallback) {
    const { canvas, context } = this.getNewCanvas()
    canvas.width = this.width
    canvas.height = this.height

    const layers = this.getSortedLayers()

    layers.forEach((x) => {
      if (!x.imageData) {
        const { canvas: newcanvas, context: newcontext } = this.getNewCanvas()
        newcanvas.width = x.image.width
        newcanvas.height = x.image.height
        newcontext.drawImage(x.image, 0, 0)
        x.imageData = newcontext.getImageData(0, 0, newcanvas.width, newcanvas.height)
      }
      if (x.colors.length) {
        for (let i = 0; i < x.colors.length; i++) {
          const color = this.getColorHexFromType(x.colors[i])
          this.drawImageToCanvas(x.imageData, context, i, color)
        }
      } else {
        this.drawImageToCanvas(x.imageData, context)
      }
    })
    if (callback) {
      callback(canvas)
    }
    return canvas
  }

  private drawShopImage (item: AvatarItemInfo, callback?: AvatarItemCallback, reject?: Rejection) {
    const alias = this.getShopItemAlias(item)
    if (!this.isAuthoringMode && alias && !!this.cachedShopImages[alias]) {
      if (callback) {
        callback(this.cachedShopImages[alias])
        return
      }
    }

    let shopImage = ''
    if (item.image) {
      shopImage = item.image.fullSizePath!
    } else if (item.new_image) {
      shopImage = item.new_image.data!
    }
    if (shopImage) {
      // If item has dedicated shop image
      this.loadShopImage(item, callback, reject)
    } else {
      // If not, just draw the item on its own
      this.drawSingleItem(item, callback, reject)
    }
  }

  private loadShopImage (item: AvatarItemInfo, callback?: AvatarItemCallback, reject?: Rejection) {
    let shopImage = ''
    if (item.image) {
      shopImage = this.formatImageURL(item.image.fullSizePath || '')
    } else if (item.new_image) {
      shopImage = item.new_image.data!
    }
    const image = this.getNewImage()
    const timeout = setTimeout (() => {
      console.error('Image load timeout', shopImage)
      if (reject) {
        reject()
      }
    }, 6000)
    image.crossOrigin = 'anonymous'
    image.onload = () => {
      clearTimeout(timeout)
      this._drawShopImage(item, callback, reject)
    }
    image.onerror = (e) => { 
      console.log(e)
      clearTimeout(timeout)
      console.error('AVATAR DRAW FAILED - IMAGE NOT FOUND', shopImage)
      if (!image.src.includes('noCache=') && !image.src.includes('data:image/png;base64')) {
        this.noCache = true
        image.src = this.formatImageURL(image.src)
      } else if (reject) {
        reject()
      }
    }
    image.src = shopImage
    this.shopItems = this.shopItems.filter(x => x.key !== item.key)
    this.shopItems.push({
      key: item.key,
      image
    })
  }


  private _drawShopImage (item: AvatarItemInfo, callback?: AvatarItemCallback, reject?: Rejection) {
    const shopItem = this.shopItems.find(x => x.key === item.key)
    if (!shopItem) {
      if (reject) {
        reject()
      }
      return
    }

    if (!shopItem.image.width) {
      // Somehow the image has been considered as loaded but as a 0x0 image... 
      // Remove duff record from shopItems
      console.log('retrying to get image')
      this.shopItems = this.shopItems.filter(x => x.key !== item.key)
      if (this.canRetry()) {
        this.drawShopImage(item, callback, reject)
      }
      return
    }

    const { canvas, context } = this.getNewCanvas()
    const frames = Math.max(1, item.colors.length)
    canvas.width = shopItem.image.width
    canvas.height = shopItem.image.height
    if (!shopItem.imageData) {
      context.drawImage(shopItem.image, 0, 0)
      shopItem.imageData = context.getImageData(0, 0, canvas.width, canvas.height)
    }
    canvas.width = Math.round(shopItem.image.width / frames)
    if (item.colors.length) {
      for (let i = 0; i < item.colors.length; i++) {
        const color = this.getColorHexFromType(item.colors[i])
        this.drawImageToCanvas(shopItem.imageData, context, i, color)
      }
    } else {
      this.drawImageToCanvas(shopItem.imageData, context)
    }

    // const cropped = this.cropCanvas(canvas, context, 10)

    const alias = this.getShopItemAlias(item)
    if (!this.isAuthoringMode) {
      this.cachedShopImages[alias] = canvas
    }
    if (callback) {
      callback(canvas)
    }
  }

  private drawSingleItem (item: AvatarItemInfo, callback?: AvatarItemCallback, reject?: Rejection) {
    if (!item.layers.layers.length) {
      if (reject) {
        reject()
      }
      return
    }

    let shopImage = ''
    if (item.image) {
      shopImage = item.image.fullSizePath!
    } else if (item.new_image) {
      shopImage = item.new_image.data!
    }

    const alias = shopImage ? this.getShopItemAlias(item) : this.getSingleItemAlias(item)
    if (!this.isAuthoringMode && alias && !!this.cachedShopImages[alias]) {
      if (callback) {
        callback(this.cachedShopImages[alias])
        return
      }
    }

    if (shopImage) {
      this.addSingleItem(item, callback, reject)
    } else {
      const loaded = this.layers.filter(x => x.key === item.key && x.image && !!x.image.width)
      const uniqLoaded = _.uniqBy(loaded, 'itemLayerIndex')
      if (uniqLoaded.length >= item.layers.layers.length) {
        this._drawSingleItem(item, callback)
      } else {
        this.addSingleItem(item, callback, reject)
      }
    }

  }

  private addSingleItem (item: AvatarItemInfo, callback?: AvatarItemCallback, reject?: Rejection) {
    const { key, type } = item
    item.layers.layers.forEach((x, i) => {
      if (!x.image && !x.new_image) { return }
      let imgUrl = ''
      if (x.image) {
        imgUrl = this.formatImageURL(x.image.fullSizePath || '')
      } else if (x.new_image) {
        imgUrl = x.new_image.data!
      }
      if (!imgUrl) { return }
      const layer = x.layer + 1
      const src = imgUrl
      const image = this.getNewImage()
      const timeout = setTimeout (() => {
        console.error('Image load timeout', src)
        if (reject) {
          reject()
        }
      }, 6000)
      image.crossOrigin = 'anonymous'
      image.onload = () => {
        clearTimeout(timeout)
        this.onSingleItemImageLoad(item, callback)
      }
      image.onerror = (e) => { 
        console.log(e)
        clearTimeout(timeout)
        console.error('AVATAR DRAW FAILED - IMAGE NOT FOUND', imgUrl);
        if (!image.src.includes('noCache=') && !image.src.includes('data:image/png;base64')) {
          this.noCache = true
          image.src = this.formatImageURL(image.src)
        }
      }
      image.src = src
      this.layers.push({ key, itemLayerIndex: i, image, layer, active: false, type, colors: x.colors })
    })
  }

  private onSingleItemImageLoad (item: AvatarItemInfo, callback?: AvatarItemCallback) {
    const loaded = this.layers.filter(x => x.key === item.key && x.image && !!x.image.width)
    const uniqLoaded = _.uniqBy(loaded, 'itemLayerIndex')
    if (uniqLoaded.length >= item.layers.layers.length) {
      this._drawSingleItem(item, callback)
    }
  }

  private _drawSingleItem (item: AvatarItemInfo, callback?: AvatarItemCallback) {
    if (!item) {
      return
    }
    const { canvas: mainCanvas, context: mainContext } = this.getNewCanvas()
    mainCanvas.width = this.width
    mainCanvas.height = this.height

    const layers = this.layers.filter(x => x.key === item.key)
    layers.sort((a, b) => a.layer - b.layer)

    for (let i = 0; i < layers.length; i++) {
      const layer = layers[i]
      if (!layer) { continue }
      // layer.active = true
      const { canvas, context } = this.getNewCanvas()
      if (!layer.imageData) {
        canvas.width = layer.image.width
        canvas.height = layer.image.height
        context.drawImage(layer.image, 0, 0)
        layer.imageData = context.getImageData(0, 0, canvas.width, canvas.height)
      }
      canvas.width = this.width
      canvas.height = this.height
      if (layer.colors.length) {
        for (let i = 0; i < layer.colors.length; i++) {
          const color = this.getColorHexFromType(layer.colors[i])
          this.drawImageToCanvas(layer.imageData, mainContext, i, color)
        }
      } else {
        this.drawImageToCanvas(layer.imageData, mainContext)
      }
    }
    const cropped = this.cropCanvas(mainCanvas, mainContext, 10)

    const alias = this.getSingleItemAlias(item)
    if (!this.isAuthoringMode) {
      this.cachedShopImages[alias] = cropped
    }
    if (callback) {
      callback(cropped)
    }
  }

  private cropCanvas (cnvs: CanvasForUseInAvatarRendering, ctx: CanvasRenderingContext2D, padding: number): CanvasForUseInAvatarRendering {
    // Get image data
    const imageData = ctx.getImageData(0, 0, this.width, this.height)
    const { width, height, data } = imageData

    // Find bounding box of non-transparent area
    let minX = width
    let minY = height
    let maxX = 0
    let maxY = 0
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const alpha = data[(y * width + x) * 4 + 3]
        if (alpha > 0) {
          minX = Math.min(minX, x)
          minY = Math.min(minY, y)
          maxX = Math.max(maxX, x)
          maxY = Math.max(maxY, y)
        }
      }
    }

    // Add padding
    minX = Math.max(0, minX - padding)
    minY = Math.max(0, minY - padding)
    maxX = Math.min(width - 1, maxX + padding)
    maxY = Math.min(height - 1, maxY + padding)

    // Calculate new dimensions
    const newWidth = maxX - minX + 1
    const newHeight = maxY - minY + 1

    // Create new canvas
    const { canvas, context } = this.getNewCanvas()
    canvas.width = newWidth
    canvas.height = newHeight

    // Copy non-transparent area to new canvas
    // @ts-ignore
    context!.drawImage(cnvs, minX, minY, newWidth, newHeight, 0, 0, newWidth, newHeight)

    return canvas
  }

  private setKeyAsActive (key: string) {
    const layers = this.layers.filter(x => x.key === key)
    layers.forEach((layer) => {
      const type = layer.type
      if (type !== 'default' && type !== 'extra') {
        const others = this.layers.filter(x => x.type === type && x.key !== key)
        others.forEach((x) => { x.active = false })
      }
      layer.active = true
    })
  }

  private getSortedLayers () {
    const layers = [...this.layers].filter(x => x.active).sort((a, b) => {
      if (a.layer > b.layer) { return 1 }
      if (a.layer < b.layer) { return -1 }
      if (a.layer === b.layer) {
        const ai = drawPriority.indexOf(a.type)
        const bi = drawPriority.indexOf(b.type)
        if (ai > bi) { return 1 }
        if (ai < bi) { return -1 }
      }
      return 0
    })
    return layers
  }

  private getColorHexFromType (type: AvatarColor) {
    switch (type) {
      case 'backgroundColor': return this.backgroundColor
      case 'eyeColor': return this.eyeColor
      case 'hairColor': return this.hairColor
      case 'shirtColor1': return this.shirtColor1
      case 'shirtColor2': return this.shirtColor2
      case 'skinColor': return this.skinColor
      case 'headwearColor': return this.headwearColor
      default: return undefined
    }
  }

  private getSingleItemAlias (item: AvatarItemInfo) {
    const colors = item.layers.layers.flatMap(x => x.colors.filter(y => y !== ''))
    let alias = item.key || `new-item-${Math.floor(Math.random() * 1000)}`
    for (let i = 0; i < colors.length; i++) {
      alias += this.getColorHexFromType(colors[i])
    }
    return alias
  }
  private getShopItemAlias (item: AvatarItemInfo) {
    let alias = item.key || `new-item-${Math.floor(Math.random() * 1000)}`
    for (let i = 0; i < item.colors.length; i++) {
      alias += this.getColorHexFromType(item.colors[i])
    }
    return alias
  }

  private getImage (url: string) {
    const src = this.formatImageURL(url)
    this.loading = true
    const img = this.getNewImage()
    img.crossOrigin = 'anonymous'
    img.onload = () => { 
      this.onImageLoad()
    }
    img.onerror = (e) => { 
      console.error('AVATAR DRAW FAILED - IMAGE NOT FOUND', url);
      if (!url.includes('noCache=') && !url.includes('data:image/png;base64')) {
        this.noCache = true
        img.src = this.formatImageURL(url)
      }
    }
    img.src = src
    return img
  }

  private formatImageURL (url:string) {
    let prefix = '?'
    let addNoCache = this.noCache
    if (/\?.*?=/.test(url)) { prefix = '&' }
    if (url.includes('data:image/png;base64')) { addNoCache = false }
    return addNoCache ? url + `${prefix}noCache=${Math.floor(Math.random() * 10000)}` : url
  }

  private onImageLoad () {
    if (this.layers.filter(x => !x.image.width).length) {
      // console.log(this.layers.filter(x => !x.image.width).length, ' images remaining')
    } else {
      this.loading = false
    }
  }

  private drawImageToCanvas (imageData: ImageData, context: CanvasRenderingContext2D, offset?: number, tint?: string) {
    const xoff = offset || 0
    if (tint) {
      const tintedImageData = this.tintImage(imageData, xoff, tint, context.canvas.width, context.canvas.height)
      const src = this.imageDataToCanvas(tintedImageData, 0, context.canvas.width, context.canvas.height) as CanvasForUseInAvatarRendering
      // console.log(src.toDataURL())
      context.drawImage(src as CanvasImageSource, 0, 0)
    } else {
      const src = this.imageDataToCanvas(imageData, xoff, context.canvas.width, context.canvas.height)
      context.drawImage(src, 0, 0)
    }
  }

  private getBlankImageData (width: number, height: number) {
    const { canvas, context } = this.getNewCanvas()
    canvas.width = width
    canvas.height = height
    return context.getImageData(0, 0, width, height)
  }

  private tintImage (imageData: ImageData, offset: number, tint: string, width: number, height: number) {
    // context!.drawImage(image, -(Math.round(offset * canvas.width)), 0)
    // const imageData = context!.getImageData(0, 0, canvas.width, canvas.height)

    const newData = this.getBlankImageData(width, height)

    const x = offset * width
    for (let row = 0; row < height; row++) {
      for (let col = 0; col < width; col++) {
        const sourceIndex = (row * imageData.width + (col + x)) * 4
        const destIndex = (row * width + col) * 4

        newData.data[destIndex] = imageData.data[sourceIndex]
        newData.data[destIndex + 1] = imageData.data[sourceIndex + 1]
        newData.data[destIndex + 2] = imageData.data[sourceIndex + 2]
        newData.data[destIndex + 3] = imageData.data[sourceIndex + 3]
      }
    }
    const RGB = this.hexToRgb(tint)

    for (let i = 0; i < newData.data.length; i += 4) {
      newData.data[i] = newData.data[i] * (RGB.r / 255) // Red channel
      newData.data[i + 1] = newData.data[i + 1] * (RGB.g / 255) // Green channel
      newData.data[i + 2] = newData.data[i + 2] * (RGB.b / 255) // Blue channel
    }

    return newData
  }

  private imageDataToCanvas (imageData: ImageData, offset: number, width: number, height: number): CanvasImageSource {
    const { canvas, context } = this.getNewCanvas()
    canvas.width = width
    canvas.height = height
    context.putImageData(imageData, -(offset * width), 0)
    return canvas as CanvasImageSource
  }

  private hexToRgb (hex: string) {
    // Remove '#' if present
    hex = hex.replace(/^#/, '')

    // Convert to RGB
    const bigint = parseInt(hex, 16)
    const r = (bigint >> 16) & 255
    const g = (bigint >> 8) & 255
    const b = bigint & 255

    return { r, g, b }
  }

  private canRetry () {
    if (this.retryLimit > 0) {
      return true
    }
    this.retryLimit = 100
    return false
  }
}
